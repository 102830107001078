/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import Header from "./header"

const GlobalStyles = createGlobalStyle`
  html {
    font-display: block;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    // overflow: hidden;
    overflow-x: hidden;
    // height: 100%;
    background: #191919;
  }
  body {
    font-display: block;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow: auto;
  }
`;

const MainLayout = styled.main`
  position: relative;
  min-height: 100vh;
  background: #191919;
  margin-top: 110px;
`;

const Content = styled.div`
  padding-bottom: 8rem;    /* Footer height */
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8rem; 
  background: rgb(64,199,184);
  background: radial-gradient(circle, rgba(64,199,184,1) 0%, rgba(255,255,255,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-family: 'VCR';
    background: linear-gradient(to right, #434371 0%, #F0038B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata.title} />
      <MainLayout>
        <Content>
          <div>
            {children}
          </div>
        </Content>
        <Footer>
          <p>{new Date().getFullYear()}, Mitch Moore</p>
        </Footer>
      </MainLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
}

export default Layout
