import React from "react"
import styled from "styled-components"
import Image from "./image"
import logo from "../images/logo-alt.png"

const BannerContainer = styled.div`
  height: calc(100vh - 150px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 100%;
    max-width: 450px;
  }

  h2 {
    font-family: 'VCR';
    font-weight: 400;
    background: linear-gradient(to right, #434371 0%, #F0038B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 3rem;
  }

  .call-to-actions {
    button {
      background: #191919!important;
      color: #40C7B8;
      text-transform: uppercase;
      text-decoration: none;
      background: #ffffff;
      padding: 20px 30px;
      border: 4px solid #40C7B8 !important;
      display: inline-block;
      transition: all 0.4s ease 0s;

      &:hover {
        cursor: pointer;
        background: #40C7B8!important;
        color: white;
        transition: all 0.4s ease 0s;
      }
    }
  }

  @media only screen and (max-width: 1270px) {
    img {
      width: 100%;
      max-width: 300px;
    }

    h2 {
      display: none;
    }
  }
`;

const Banner = () => (
  <BannerContainer>
    <img src={logo} />
    <h2>metro<br />detroit<br />based<br />web developer</h2>
  </BannerContainer>
)

export default Banner