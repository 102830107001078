import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import logo from "../images/condensed-logo.png"

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #434371;
  padding: 0 15px;

  img {
    max-height: 90px;
  }
`;

const HeaderTitle = styled.div`
  max-width: 80vw;
  padding: 0.5rem;

  h1 a {
    /* font-family: "Varela Round"; */
    font-family: "VCR";
    font-weight: 400;
    background: linear-gradient(to right, #ffae03 0%, #F0038B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`; 

const HeaderActions = styled.div`
  margin-right: 50px;

  ul {
    display: flex;

    li {
      list-style: none;
      width: fit-content;
      min-width: 100px;
      padding: 0 15px;

      a {
        font-family: "VCR";
        font-weight: 700;
        color: white;
        /* background: linear-gradient(to right, #ffae03 0%, #F0038B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <HeaderTitle>
      <h1 style={{ margin: 0, fontFamily: 'Moon' }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src={logo} />
        </Link>
      </h1>
    </HeaderTitle>
    <HeaderActions>
      <ul>
        <li>
          <Link to={`/about`}>about</Link>
        </li>
        <li>
          <a>blog</a>
        </li>
        <li>
          <a>contact</a>
        </li>
      </ul>
    </HeaderActions>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
