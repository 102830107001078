import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import gridField from "../../static/img/grid-field-small.svg"

const AboutMe = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .top-divider {
    width: 100%;
    height: 3px;
    background: #40C7B8;
    box-shadow: 0px 3px 5px #40C7B8, 0px -3px 5px #40C7B8;
  }

  .grid-top {
    transform: rotate(180deg);
  }

  img {
    margin-bottom: 0;
  }

  .about-me__content-wrapper {
    @keyframes left-to-right {
      0% { left: -40%; }
      100% { left: 125%; }
    }

    @keyframes right-to-left {
      0% { left: 125%; }
      100% { left: -40%; }
    }

    display: flex;
    justify-content: center;
    padding: 3rem 0;
    position: relative;

    .line {
      position: absolute;
      border-radius: 16px;

      &.left-line {
        left: -22%;
        animation-name: left-to-right;
        animation-duration: 4s;
        animation-iteration-count: infinite;
      }

      &.right-line {
        left: 105%;
        animation-name: right-to-left;
        animation-duration: 4s;
        animation-iteration-count: infinite;
      }

      &.line-1 {
        top: 10%;
        width: 100px;
        height: 10px;
        animation-delay: 0s;
        background: linear-gradient(to right,#434371 0%,#F0038B 100%);
      }

      &.line-2 {
        top: 30%;
        width: 200px;
        height: 10px;
        animation-delay: 0.5s;
        background: linear-gradient(to right,#F0038B 0%,#434371 100%);
      }

      &.line-3 {
        top: 50%;
        width: 300px;
        height: 10px;
        animation-delay: 1.5s;
        background: linear-gradient(to right,#F0038B 0%,#ffae03 100%);
      }

      &.line-4 {
        top: 70%;
        width: 400px;
        height: 10px;
        animation-delay: 2s;
        background: linear-gradient(to right,#F0038B 0%,#434371 100%);
      }

      &.line-5 {
        top: 90%;
        width: 200px;
        height: 10px;
        animation-delay: 0.5s;
        background: linear-gradient(to right,#ffae03 0%,#434371 100%);
      }
    }

    .about-me__content {
      position: relative;
      max-width: 640px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: 'VCR';
      color: white;
      text-align: center;
      padding: 20px;
      border: 1px solid white;
      background: rgba(0, 0, 0, 0.7);

      h3 {
        font-family: 'VCR';
        text-transform: uppercase;
        font-size: 2rem;
      }
    }
  }
`;

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Home"/>
      <Banner />
      <AboutMe>
        <img className="grid-top" src={gridField} alt="grid field" />
        <div className="about-me__content-wrapper">
          <div className="line left-line line-1"></div>
          <div className="line right-line line-2"></div>
          <div className="line left-line line-3"></div>
          <div className="line right-line line-4"></div>
          <div className="line left-line line-5"></div>
          <div className="about-me__content">
            <h3>Hi, I'm Mitch!</h3>
            <p>
              I am a Web Developer currently working in the Metro Detroit Michigan area for Logicdrop as well as
              designing and building web applications on the side. I am currently working on getting the site up
              and running, so stay tuned!
            </p>
          </div>
        </div>
        <img className="grid-bottom" src={gridField} alt="grid field" />
      </AboutMe>
    </Layout>
  )
}

export default IndexPage
